/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Scan } from '../models/scan';
import { ScanPost } from '../models/scan-post';
import { ScanPut } from '../models/scan-put';
@Injectable({
  providedIn: 'root',
})
class ScansService extends __BaseService {
  static readonly getOrganizationsCkOrgIdAccountsAccountIdScansPath = '/organizations/{ck_org_id}/accounts/{account_id}/scans';
  static readonly postOrganizationsCkOrgIdAccountsAccountIdScansPath = '/organizations/{ck_org_id}/accounts/{account_id}/scans';
  static readonly deleteOrganizationsCkOrgIdAccountsAccountIdScansScanIdPath = '/organizations/{ck_org_id}/accounts/{account_id}/scans/{scan_id}';
  static readonly getOrganizationsCkOrgIdAccountsAccountIdScansScanIdPath = '/organizations/{ck_org_id}/accounts/{account_id}/scans/{scan_id}';
  static readonly putOrganizationsCkOrgIdAccountsAccountIdScansScanIdPath = '/organizations/{ck_org_id}/accounts/{account_id}/scans/{scan_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ScansService.GetOrganizationsCkOrgIdAccountsAccountIdScansParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS Account Scans info
   */
  getOrganizationsCkOrgIdAccountsAccountIdScansResponse(params: ScansService.GetOrganizationsCkOrgIdAccountsAccountIdScansParams): __Observable<__StrictHttpResponse<{data?: Array<Scan>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/scans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Scan>}>;
      })
    );
  }
  /**
   * @param params The `ScansService.GetOrganizationsCkOrgIdAccountsAccountIdScansParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS Account Scans info
   */
  getOrganizationsCkOrgIdAccountsAccountIdScans(params: ScansService.GetOrganizationsCkOrgIdAccountsAccountIdScansParams): __Observable<{data?: Array<Scan>}> {
    return this.getOrganizationsCkOrgIdAccountsAccountIdScansResponse(params).pipe(
      __map(_r => _r.body as {data?: Array<Scan>})
    );
  }

  /**
   * @param params The `ScansService.PostOrganizationsCkOrgIdAccountsAccountIdScansParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `scan_info`: new AWS scan information
   *
   * @return Created AWS Account Scan info
   */
  postOrganizationsCkOrgIdAccountsAccountIdScansResponse(params: ScansService.PostOrganizationsCkOrgIdAccountsAccountIdScansParams): __Observable<__StrictHttpResponse<{data?: Scan}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.scanInfo;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/scans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Scan}>;
      })
    );
  }
  /**
   * @param params The `ScansService.PostOrganizationsCkOrgIdAccountsAccountIdScansParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `scan_info`: new AWS scan information
   *
   * @return Created AWS Account Scan info
   */
  postOrganizationsCkOrgIdAccountsAccountIdScans(params: ScansService.PostOrganizationsCkOrgIdAccountsAccountIdScansParams): __Observable<{data?: Scan}> {
    return this.postOrganizationsCkOrgIdAccountsAccountIdScansResponse(params).pipe(
      __map(_r => _r.body as {data?: Scan})
    );
  }

  /**
   * @param params The `ScansService.DeleteOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams` containing the following parameters:
   *
   * - `scan_id`: scan id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   */
  deleteOrganizationsCkOrgIdAccountsAccountIdScansScanIdResponse(params: ScansService.DeleteOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/scans/${params.scanId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScansService.DeleteOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams` containing the following parameters:
   *
   * - `scan_id`: scan id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   */
  deleteOrganizationsCkOrgIdAccountsAccountIdScansScanId(params: ScansService.DeleteOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams): __Observable<null> {
    return this.deleteOrganizationsCkOrgIdAccountsAccountIdScansScanIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ScansService.GetOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams` containing the following parameters:
   *
   * - `scan_id`: scan id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS Account Scan info
   */
  getOrganizationsCkOrgIdAccountsAccountIdScansScanIdResponse(params: ScansService.GetOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams): __Observable<__StrictHttpResponse<{data?: Scan}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/scans/${params.scanId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Scan}>;
      })
    );
  }
  /**
   * @param params The `ScansService.GetOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams` containing the following parameters:
   *
   * - `scan_id`: scan id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS Account Scan info
   */
  getOrganizationsCkOrgIdAccountsAccountIdScansScanId(params: ScansService.GetOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams): __Observable<{data?: Scan}> {
    return this.getOrganizationsCkOrgIdAccountsAccountIdScansScanIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Scan})
    );
  }

  /**
   * @param params The `ScansService.PutOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams` containing the following parameters:
   *
   * - `scan_id`: scan id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `scan_info`: updated AWS Account Scan information
   *
   * @return Updated AWS Scan info
   */
  putOrganizationsCkOrgIdAccountsAccountIdScansScanIdResponse(params: ScansService.PutOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams): __Observable<__StrictHttpResponse<{data?: Scan}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    __body = params.scanInfo;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/scans/${params.scanId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Scan}>;
      })
    );
  }
  /**
   * @param params The `ScansService.PutOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams` containing the following parameters:
   *
   * - `scan_id`: scan id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `scan_info`: updated AWS Account Scan information
   *
   * @return Updated AWS Scan info
   */
  putOrganizationsCkOrgIdAccountsAccountIdScansScanId(params: ScansService.PutOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams): __Observable<{data?: Scan}> {
    return this.putOrganizationsCkOrgIdAccountsAccountIdScansScanIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Scan})
    );
  }
}

module ScansService {

  /**
   * Parameters for getOrganizationsCkOrgIdAccountsAccountIdScans
   */
  export interface GetOrganizationsCkOrgIdAccountsAccountIdScansParams {

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;
  }

  /**
   * Parameters for postOrganizationsCkOrgIdAccountsAccountIdScans
   */
  export interface PostOrganizationsCkOrgIdAccountsAccountIdScansParams {

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;

    /**
     * new AWS scan information
     */
    scanInfo?: ScanPost;
  }

  /**
   * Parameters for deleteOrganizationsCkOrgIdAccountsAccountIdScansScanId
   */
  export interface DeleteOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams {

    /**
     * scan id (e.g., 'uuid-uuid-uuiduuid')
     */
    scanId: string;

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;
  }

  /**
   * Parameters for getOrganizationsCkOrgIdAccountsAccountIdScansScanId
   */
  export interface GetOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams {

    /**
     * scan id (e.g., 'uuid-uuid-uuiduuid')
     */
    scanId: string;

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;
  }

  /**
   * Parameters for putOrganizationsCkOrgIdAccountsAccountIdScansScanId
   */
  export interface PutOrganizationsCkOrgIdAccountsAccountIdScansScanIdParams {

    /**
     * scan id (e.g., 'uuid-uuid-uuiduuid')
     */
    scanId: string;

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;

    /**
     * updated AWS Account Scan information
     */
    scanInfo?: ScanPut;
  }
}

export { ScansService }
