import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { OrgWrapperService } from './org.wrapper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private orgWrapperService: OrgWrapperService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedReq = req.clone(
      {headers: req.headers.append('Authorization', this.authService.authHeader)}
      );

    if (this.orgWrapperService.ckVND != null) {
      modifiedReq = modifiedReq.clone(
        {headers: modifiedReq.headers.append('vnd-cloudkeep-io-user-jwt', this.orgWrapperService.ckVND)}
      );
    }

    return next.handle(modifiedReq);
  }
}
