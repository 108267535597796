import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {CardsService} from '../ck-aws-api/services';
import {CardEBSSummary} from '../ck-aws-api/models';
import { SNAPSHOTS_MOCK, EbsSnapInfoMock, ATTACHMENT_MOCK, PUBLIC_SNAPSHOT_MOCK, EbsDetachedInfoMock, EbsPublicSnapInfoMock } from './cards.wrapper.service.mock';
import { groupBy, map2array, sortBy, descendingSorter, ascendingSorter } from './utils';
import { HttpMockupService } from './services/http-mockup.service';
import { CardDataView, CardDataViewParams } from './cards.model';

export const SNAPSHOT_COLUMNS = [
  { prop: 'last_snapshot_time', name: 'Last Snapshot Time' },
  { prop: 'volume_id', name: 'EBS Volume ID' },
  { prop: 'instance_id', name: 'EC2 Instance' },
  { prop: 'availability_zone_id', name: 'Availability Zone' },
  { prop: 'region_id', name: 'Region' },
  { prop: 'account_alias', name: 'Account' },
];
export const ATTACHMENT_COLUMNS = [
  { prop: 'last_attachment_time', name: 'Last Attachment Time' },
  { prop: 'volume_id', name: 'EBS Volume ID' },
  { prop: 'instance_id', name: 'EC2 Instance' },
  { prop: 'availability_zone_id', name: 'Availability Zone' },
  { prop: 'region_id', name: 'Region' },
  { prop: 'account_alias', name: 'Account' },
];
export const PUBLIC_SNAPSHOT_COLUMNS = [
  { prop: 'public_time', name: 'Public Time' },
  { prop: 'volume_id', name: 'EBS Volume ID' },
  { prop: 'instance_id', name: 'EC2 Instance' },
  { prop: 'availability_zone_id', name: 'Availability Zone' },
  { prop: 'region_id', name: 'Region' },
  { prop: 'account_alias', name: 'Account' },
];

export const TIME_GROUPS = [
  { id: 'day', text: '< 1 day' },
  { id: 'week', text: '1 day - 1 week' },
  { id: 'month', text: '1 week - 1 month' },
  { id: 'more_than_month', text: '> 1 month' },
]

interface SnapshotsState {
  data: EbsSnapInfoMock[];
  view: SnapshotsView;
  stats: any;
};
interface AttachmentsState {
  data: EbsDetachedInfoMock[];
  view: AttachmentsView;
  stats: any;
};
interface PublicSnapshotsState {
  data: EbsPublicSnapInfoMock[];
  view: PublicSnapshotsView;
  stats: any;
};

export interface SnapshotsView extends CardDataView<EbsSnapInfoMock> {}
export interface AttachmentsView extends CardDataView<EbsDetachedInfoMock> {}
export interface PublicSnapshotsView extends CardDataView<EbsPublicSnapInfoMock> {}

export const DEFAULT_VIEW_PARAMS_SNAPSHOTS = { sorting: [{ prop: 'last_snapshot_time', dir: 'desc' }] } as CardDataViewParams;
export const DEFAULT_VIEW_PARAMS_ATTACHMENTS = { sorting: [{ prop: 'last_attachment_time', dir: 'desc' }] } as CardDataViewParams;
export const DEFAULT_VIEW_PARAMS_PUBLIC_SNAPSHOTS = { sorting: [{ prop: 'public_time', dir: 'desc' }] } as CardDataViewParams;

@Injectable({
  providedIn: 'root'
})
export class CardsWrapperService {

  private _summary: CardEBSSummary;
  private _snapshots: SnapshotsState;
  private _attachments: AttachmentsState;
  private _publicSnapshots: PublicSnapshotsState;

  get summary() { return this._summary; }
  get snapshots() { return this._snapshots && this._snapshots.view; }
  get snapshotStats() { return this._snapshots && this._snapshots.stats; }
  get attachments() { return this._attachments && this._attachments.view; }
  get attachmentStats() { return this._attachments && this._attachments.stats; }
  get publicSnapshots() { return this._publicSnapshots && this._publicSnapshots.view; }
  get publicSnapshotStats() { return this._publicSnapshots && this._publicSnapshots.stats; }

  constructor(
    private cardsService: CardsService,
    private httpMockup: HttpMockupService,
  ) {}

  public async getSummary(ckOrgId: string) {
    // const {data} = await this.cardsService
    //   .getOrganizationsCkOrgIdCardsEbsSummary(ckOrgId)
    //   .toPromise();

    this._summary = {
      num_accounts: 11,
      num_azs: 22,
      num_instances: 2,
      num_regions: 3,
      num_volumes: 43,
      size_gib: 450,
    };
    return this._summary;
  }

  public async getSnapshots(ckOrgId: string, viewParams = DEFAULT_VIEW_PARAMS_SNAPSHOTS) {
    const {data} = await this.httpMockup.get(`/organizations/${ckOrgId}/cards/ebs-snap-info`, undefined, SNAPSHOTS_MOCK());
    this._snapshots = { data } as any;
    return this.getSnapshotsView(viewParams);
  }

  public async getAttachments(ckOrgId: string, viewParams = DEFAULT_VIEW_PARAMS_ATTACHMENTS) {
    const {data} = await this.httpMockup.get(`/organizations/${ckOrgId}/cards/ebs-detached-info`, undefined, ATTACHMENT_MOCK());
    this._attachments = { data } as any;
    return this.getAttachmentsView(viewParams);
  }

  public async getPublicSnapshots(ckOrgId: string, viewParams = DEFAULT_VIEW_PARAMS_PUBLIC_SNAPSHOTS) {
    const {data} = await this.httpMockup.get(`/organizations/${ckOrgId}/cards/ebs-public-snap-info`, undefined, PUBLIC_SNAPSHOT_MOCK());
    this._publicSnapshots = { data } as any;
    return this.getPublicSnapshotsView(viewParams);
  }

  public getSnapshotsView(params: CardDataViewParams) {
    if (!this._snapshots || !this._snapshots.data) {
      return;
    }

    const { sorting, filter } = params;

    let { rows, stats } = this._processData(this._snapshots.data, 
      sorting && sorting[0] && sorting[0].prop, 
      sorting && sorting[0] && sorting[0].dir === 'desc',
      'last_snapshot_time',
      'last_snapshot_time_group',
      'last_snapshot_time_group_pos',
    );

    if (filter && filter.timeGroupId) {
      rows = rows.filter(row => row.last_snapshot_time_group === filter.timeGroupId);
    }

    this._snapshots.view = { rows, params };
    this._snapshots.stats = stats;
    return this._snapshots;
  }

  public getAttachmentsView(params: CardDataViewParams) {
    if (!this._attachments || !this._attachments.data) {
      return;
    }

    const { sorting, filter } = params;

    let { rows, stats } = this._processData(this._attachments.data, 
      sorting && sorting[0] && sorting[0].prop, 
      sorting && sorting[0] && sorting[0].dir === 'desc',
      'last_attachment_time',
      'last_attachment_time_group',
      'last_attachment_time_group_pos',
    );

    if (filter && filter.timeGroupId) {
      rows = rows.filter(row => row.last_attachment_time_group === filter.timeGroupId);
    }

    this._attachments.view = { rows, params };
    this._attachments.stats = stats;
    return this._attachments;
  }

  public getPublicSnapshotsView(params: CardDataViewParams) {
    if (!this._publicSnapshots || !this._publicSnapshots.data) {
      return;
    }

    const { sorting, filter } = params;

    let { rows, stats } = this._processData(this._publicSnapshots.data, 
      sorting && sorting[0] && sorting[0].prop, 
      sorting && sorting[0] && sorting[0].dir === 'desc',
      'public_time',
      'public_time_group',
      'public_time_group_pos',
    );

    if (filter && filter.timeGroupId) {
      rows = rows.filter(row => row.public_time_group === filter.timeGroupId);
    }

    this._publicSnapshots.view = { rows, params };
    this._publicSnapshots.stats = stats;
    return this._publicSnapshots;
  }

  private _processData<T>(data: T[], sortByProp: string, sortDesc: boolean, timeCol: string, timeGroupCol: string, timeGroupPosCol: string) {
    const rows = sortBy(
      data,
      row => moment(row[sortByProp]).toDate().valueOf(),
      sortDesc ? descendingSorter : ascendingSorter,
    );

    const stats = {} as any;
    rows.forEach(row => {

      // Snapshot time group
      const t = row[timeCol];
      row[timeGroupCol] = 
        !t ? 'never'
        : moment(t).isBetween(moment().add(-1, 'day'), moment()) ? 'day'
        : moment(t).isBetween(moment().add(-1, 'week'), moment().add(-1, 'day')) ? 'week'
        : moment(t).isBetween(moment().add(-1, 'month'), moment().add(-1, 'week')) ? 'month'
        : 'more_than_month';
      delete row[timeGroupPosCol];

      // Stats for charts
      stats[row[timeGroupCol]] = stats[row[timeGroupCol]] || 0;
      stats[row[timeGroupCol]] += 1;
      stats[row[timeGroupCol] + 'Rel'] = stats[row[timeGroupCol] + 'Rel'] || 0;;
      stats[row[timeGroupCol] + 'Rel'] += 1 / rows.length;

      if (row[timeGroupCol] !== 'never') {
        stats['hasTimestamp'] = stats['hasTimestamp'] || 0;
        stats['hasTimestamp'] += 1;

        stats['hasTimestampRel'] = stats['hasTimestampRel'] || 0;
        stats['hasTimestampRel'] += 1 / rows.length;
      }
    });

    stats['total'] = rows.length;

    if (sortByProp === timeCol) {

      map2array<any, EbsSnapInfoMock[]>(
        groupBy(rows, row => row[timeGroupCol]),
      ).forEach(group => {
        group.forEach((row, i) => {
          const posArr = ['mid'];
          if (i === 0) {
            posArr.push('first');
          }
          if (i === group.length - 1) {
            posArr.push('last');
          }
          row[timeGroupPosCol] = posArr.join(' ');
        });
      });
    }

    return {rows, stats};
  }

}
