import {Component, OnInit} from '@angular/core';
import {AuthService} from './auth.service';

@Component({
  selector: 'app-log-in',
  template: 'login'
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService) {
  }
  ngOnInit(): void {
    this.authService.login();
  }
}
