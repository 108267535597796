import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthGuard} from '../auth/auth-guard.service';
import { take } from 'rxjs/operators';
import {OrgWrapperService} from '../shared/org.wrapper.service';
import {UserWrapperService} from '../shared/user.wrapper.service';
import {User} from '../ck-user-api/models/user';

// import {UserService} from '../users/user.service';


@Injectable()
export class OrgAuthGuard implements CanActivate {

  constructor(
    private userWrapperService: UserWrapperService,
    private orgWrapperService: OrgWrapperService,
    private router: Router,
    private authGuard: AuthGuard
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const auth = await (this.authGuard.canActivate(route, state).pipe(take(1)).toPromise());
    if (!auth) {
       return false;
    }
    const currentUser: User = await this.userWrapperService.userLogIn();
    if (!currentUser) {
      return false;
    }
    // force to get ck-vnd;
    await this.orgWrapperService.getCkVND();
    const orgs = await this.orgWrapperService.getOrgs();
    return orgs != null;
  }
}
