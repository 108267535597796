import {Component, OnDestroy, OnInit, ComponentRef, OnChanges} from '@angular/core';
import {AccountWrapperService} from '../../shared/account.wrapper.service';
import {Account} from '../../ck-aws-api/models/account';
import {interval, Subscription} from 'rxjs';
import {ModalWrapperService} from '../../shared/modal.wrapper.service';

@Component({
  selector: 'app-modal-awsremove',
  templateUrl: './awsremove-component.html',
  styleUrls: ['./awsremove-component.scss'],
  inputs: ['options']
})
export class AwsRemoveComponent implements OnInit, OnDestroy, OnChanges {
  private selectedOrderIds;
  private options;
  public spinWorking = false;
  public delConfirm = '';
  private accounts: Account[];
  private updateAccountSubscription: Subscription;

  constructor(
    private accountService: AccountWrapperService,
    private modalWrapperService: ModalWrapperService
    ) {
  }

  ngOnInit() {
    if (this.options) {
      this.accounts = this.options.accounts;
      this.selectedOrderIds = this.options.selectedOrderIds;
    }
  }

  ngOnChanges(): void {
    if(this.options) {
      this.accounts = this.options.accounts;
      this.selectedOrderIds = this.options.selectedOrderIds;
    }
  }

  confirmRemove() {

    if ( this.delConfirm === 'DELETE' || this.delConfirm === 'delete' ) {
      const allProms = [];
      this.selectedOrderIds.forEach(accountAlone => {
        allProms.push(this.accountService.deleteAccount(accountAlone));
      });
      Promise.all(allProms).then(allResponse => {
        this.closeMe();
      });
    } else {
      alert('Incorrect phrase.');
    }
  }

  ngOnDestroy() {}

  closeMe() {
    this.options.closeDialogSubject.next();
    this.modalWrapperService.closeModal();
  }

}

