import { Input, Component, OnChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'chart-donut',
    templateUrl: './chart-donut.component.html',
    styleUrls: ['./chart-donut.component.scss'],
})
export class ChartDonutComponent implements OnChanges {

    @Input() title: string;
    @Input() colors: string[];
    @Input() labels: string[];
    @Input() data: number[];
    @Output() dataClick = new EventEmitter();

    @ViewChild('chartRef')
    chartRef: BaseChartDirective;

    chartData;
    chartLabels;
    chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { 
            position: 'bottom',
            labels: {
                fontSize: 12,
                boxWidth: 12,
            }
        },
        animation: {
            animateRotate: false,
        },
        tooltips: {
            enabled: false,
        },
        pieceLabel: {
            render: 'percentage',
            fontColor: ['black', 'black', 'black', 'black'],
            precision: 1,
        },
        centerTitle: '',
    };

    private _lastIndexClicked: number;

    ngOnChanges() {
        this._refresh();
    }

    onClick(e: MouseEvent) {
        const elementClicked = this.chartRef.chart.getElementAtEvent(e);
        if (!elementClicked[0]) {
            return;
        }

        const indexClicked = elementClicked[0]['_index'];

        if (this._lastIndexClicked === indexClicked) {
            // Prevent repeated clicks
            return;
        }
        
        this.dataClick.emit(indexClicked);
        this._lastIndexClicked = indexClicked;

        // remember original slice radiuses
        const {innerRadius, outerRadius} = elementClicked[0]['_model'];
        
        // restore all slices
        this.chartRef.chart.getDatasetAtEvent(e).forEach(el => {
            el['_model'].innerRadius = innerRadius;
            el['_model'].outerRadius = outerRadius;
        });

        // update the newly selected slice
        elementClicked[0]['_model'].innerRadius -= 10;
        elementClicked[0]['_model'].outerRadius += 10;

        this.chartRef.chart.render({ duration: 300, lazy: true, easing: 'linear' });
    }

    resetIndexClicked() {
        this.chartRef.update();

        delete this._lastIndexClicked;
    }

    private _refresh() {
        this.chartOptions.centerTitle = this.title;
        this.chartData = [{
            data: this.data.slice(),
            backgroundColor: this.colors.slice(),
            borderColor: this.colors.slice(),
            borderWidth: [1, 0],
        }];
        this.chartLabels = this.labels.slice();
    }

}