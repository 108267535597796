import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import { OrgSelectComponent } from '../orgs/org-select-card/org-select.component';
import { UserDetailFormComponent } from '../users/user-detail-form/user-detail-form.component';
import {MatStepper} from '@angular/material';


@Component({
  selector: 'app-sign-on-flow',
  templateUrl: './sign-on-flow.component.html',
  styleUrls: ['./sign-on-flow.component.scss']
})
export class SignOnFlowComponent implements OnInit, OnDestroy {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('UserDetailFormComponent') userDetailForm: UserDetailFormComponent;
  @ViewChild('OrgSelectComponent') orgSelectComponent: OrgSelectComponent;

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
  }

  get formUserDetail() {
    return this.userDetailForm ? this.userDetailForm.profileForm : null;
  }

}
