/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Organization } from '../models/organization';
import { UserOrgBelonging } from '../models/user-org-belonging';
import { Invitation } from '../models/invitation';
@Injectable({
  providedIn: 'root',
})
class OrganizationService extends __BaseService {
  static readonly getOrganizationsPath = '/organizations';
  static readonly postOrganizationsPath = '/organizations';
  static readonly getOrganizationsOrgIdPath = '/organizations/{org_id}';
  static readonly putOrganizationsOrgIdPath = '/organizations/{org_id}';
  static readonly deleteOrganizationsOrgIdPath = '/organizations/{org_id}';
  static readonly getOrganizationsOrgIdUsersPath = '/organizations/{org_id}/users';
  static readonly postOrganizationsOrgIdUsersPath = '/organizations/{org_id}/users';
  static readonly putOrganizationsOrgIdUsersPath = '/organizations/{org_id}/users';
  static readonly getOrganizationsOrgIdTokenPath = '/organizations/{org_id}/token';
  static readonly getOrganizationsOrgIdInvitesPath = '/organizations/{org_id}/invites';
  static readonly postOrganizationsOrgIdInvitesPath = '/organizations/{org_id}/invites';
  static readonly deleteOrganizationsOrgIdInvitesInvitationIdPath = '/organizations/{org_id}/invites/{invitation_id}';
  static readonly getOrganizationsOrgIdUsersUserIdPath = '/organizations/{org_id}/users/{user_id}';
  static readonly putOrganizationsOrgIdUsersUserIdPath = '/organizations/{org_id}/users/{user_id}';
  static readonly deleteOrganizationsOrgIdUsersUserIdPath = '/organizations/{org_id}/users/{user_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return list of organizations belong to the user
   */
  getOrganizationsResponse(): __Observable<__StrictHttpResponse<{data?: Array<Organization>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Organization>}>;
      })
    );
  }
  /**
   * @return list of organizations belong to the user
   */
  getOrganizations(): __Observable<{data?: Array<Organization>}> {
    return this.getOrganizationsResponse().pipe(
      __map(_r => _r.body as {data?: Array<Organization>})
    );
  }

  /**
   * @param org The org info to update.
   * @return the new created org
   */
  postOrganizationsResponse(org: Organization): __Observable<__StrictHttpResponse<{data?: Array<Organization>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = org;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Organization>}>;
      })
    );
  }
  /**
   * @param org The org info to update.
   * @return the new created org
   */
  postOrganizations(org: Organization): __Observable<{data?: Array<Organization>}> {
    return this.postOrganizationsResponse(org).pipe(
      __map(_r => _r.body as {data?: Array<Organization>})
    );
  }

  /**
   * @param org_id The uuid of the organizations
   * @return the selected org information
   */
  getOrganizationsOrgIdResponse(orgId: string): __Observable<__StrictHttpResponse<{data?: Array<Organization>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${orgId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Organization>}>;
      })
    );
  }
  /**
   * @param org_id The uuid of the organizations
   * @return the selected org information
   */
  getOrganizationsOrgId(orgId: string): __Observable<{data?: Array<Organization>}> {
    return this.getOrganizationsOrgIdResponse(orgId).pipe(
      __map(_r => _r.body as {data?: Array<Organization>})
    );
  }

  /**
   * @param params The `OrganizationService.PutOrganizationsOrgIdParams` containing the following parameters:
   *
   * - `org_id`: The uuid of the organizations
   *
   * - `org`: The org info to update.
   *
   * @return the updated org information
   */
  putOrganizationsOrgIdResponse(params: OrganizationService.PutOrganizationsOrgIdParams): __Observable<__StrictHttpResponse<{data?: Array<Organization>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.org;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${params.orgId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Organization>}>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.PutOrganizationsOrgIdParams` containing the following parameters:
   *
   * - `org_id`: The uuid of the organizations
   *
   * - `org`: The org info to update.
   *
   * @return the updated org information
   */
  putOrganizationsOrgId(params: OrganizationService.PutOrganizationsOrgIdParams): __Observable<{data?: Array<Organization>}> {
    return this.putOrganizationsOrgIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Array<Organization>})
    );
  }

  /**
   * @param org_id The uuid of the organizations
   * @return Successfully mark org as delete. will delete on cascade later
   */
  deleteOrganizationsOrgIdResponse(orgId: string): __Observable<__StrictHttpResponse<{data?: Array<Organization>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${orgId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Organization>}>;
      })
    );
  }
  /**
   * @param org_id The uuid of the organizations
   * @return Successfully mark org as delete. will delete on cascade later
   */
  deleteOrganizationsOrgId(orgId: string): __Observable<{data?: Array<Organization>}> {
    return this.deleteOrganizationsOrgIdResponse(orgId).pipe(
      __map(_r => _r.body as {data?: Array<Organization>})
    );
  }

  /**
   * @param org_id The uuid of the organizations
   * @return get the list of user of the org
   */
  getOrganizationsOrgIdUsersResponse(orgId: string): __Observable<__StrictHttpResponse<{data?: Array<UserOrgBelonging>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${orgId}/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<UserOrgBelonging>}>;
      })
    );
  }
  /**
   * @param org_id The uuid of the organizations
   * @return get the list of user of the org
   */
  getOrganizationsOrgIdUsers(orgId: string): __Observable<{data?: Array<UserOrgBelonging>}> {
    return this.getOrganizationsOrgIdUsersResponse(orgId).pipe(
      __map(_r => _r.body as {data?: Array<UserOrgBelonging>})
    );
  }

  /**
   * @param params The `OrganizationService.PostOrganizationsOrgIdUsersParams` containing the following parameters:
   *
   * - `org_id`: The uuid of the organizations
   *
   * - `invitation_detail`:
   *
   * @return the user is successfully invited
   */
  postOrganizationsOrgIdUsersResponse(params: OrganizationService.PostOrganizationsOrgIdUsersParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.invitationDetail;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${params.orgId}/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.PostOrganizationsOrgIdUsersParams` containing the following parameters:
   *
   * - `org_id`: The uuid of the organizations
   *
   * - `invitation_detail`:
   *
   * @return the user is successfully invited
   */
  postOrganizationsOrgIdUsers(params: OrganizationService.PostOrganizationsOrgIdUsersParams): __Observable<{}> {
    return this.postOrganizationsOrgIdUsersResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param org_id The uuid of the organizations
   * @return the org has become the default org
   */
  putOrganizationsOrgIdUsersResponse(orgId: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${orgId}/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param org_id The uuid of the organizations
   * @return the org has become the default org
   */
  putOrganizationsOrgIdUsers(orgId: string): __Observable<{}> {
    return this.putOrganizationsOrgIdUsersResponse(orgId).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param org_id The uuid of the organizations
   * @return the org's jwt token
   */
  getOrganizationsOrgIdTokenResponse(orgId: string): __Observable<__StrictHttpResponse<{token?: string, expires_in?: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${orgId}/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{token?: string, expires_in?: number}>;
      })
    );
  }
  /**
   * @param org_id The uuid of the organizations
   * @return the org's jwt token
   */
  getOrganizationsOrgIdToken(orgId: string): __Observable<{token?: string, expires_in?: number}> {
    return this.getOrganizationsOrgIdTokenResponse(orgId).pipe(
      __map(_r => _r.body as {token?: string, expires_in?: number})
    );
  }

  /**
   * @param org_id The uuid of the organizations
   * @return list of pending invitations
   */
  getOrganizationsOrgIdInvitesResponse(orgId: string): __Observable<__StrictHttpResponse<{data?: Array<Invitation>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${orgId}/invites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Invitation>}>;
      })
    );
  }
  /**
   * @param org_id The uuid of the organizations
   * @return list of pending invitations
   */
  getOrganizationsOrgIdInvites(orgId: string): __Observable<{data?: Array<Invitation>}> {
    return this.getOrganizationsOrgIdInvitesResponse(orgId).pipe(
      __map(_r => _r.body as {data?: Array<Invitation>})
    );
  }

  /**
   * @param params The `OrganizationService.PostOrganizationsOrgIdInvitesParams` containing the following parameters:
   *
   * - `org_id`: The uuid of the organizations
   *
   * - `invitation_detail`:
   *
   * @return the user is successfully invited
   */
  postOrganizationsOrgIdInvitesResponse(params: OrganizationService.PostOrganizationsOrgIdInvitesParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.invitationDetail;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${params.orgId}/invites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.PostOrganizationsOrgIdInvitesParams` containing the following parameters:
   *
   * - `org_id`: The uuid of the organizations
   *
   * - `invitation_detail`:
   *
   * @return the user is successfully invited
   */
  postOrganizationsOrgIdInvites(params: OrganizationService.PostOrganizationsOrgIdInvitesParams): __Observable<{}> {
    return this.postOrganizationsOrgIdInvitesResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `OrganizationService.DeleteOrganizationsOrgIdInvitesInvitationIdParams` containing the following parameters:
   *
   * - `org_id`: The uuid of the organization
   *
   * - `invitation_id`: the invitation id
   *
   * @return the invitation is succesfully deleted
   */
  deleteOrganizationsOrgIdInvitesInvitationIdResponse(params: OrganizationService.DeleteOrganizationsOrgIdInvitesInvitationIdParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${params.orgId}/invites/${params.invitationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.DeleteOrganizationsOrgIdInvitesInvitationIdParams` containing the following parameters:
   *
   * - `org_id`: The uuid of the organization
   *
   * - `invitation_id`: the invitation id
   *
   * @return the invitation is succesfully deleted
   */
  deleteOrganizationsOrgIdInvitesInvitationId(params: OrganizationService.DeleteOrganizationsOrgIdInvitesInvitationIdParams): __Observable<{}> {
    return this.deleteOrganizationsOrgIdInvitesInvitationIdResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `OrganizationService.GetOrganizationsOrgIdUsersUserIdParams` containing the following parameters:
   *
   * - `user_id`: the user
   *
   * - `org_id`: The uuid of the organization
   *
   * @return the relationship information of the org to the user
   */
  getOrganizationsOrgIdUsersUserIdResponse(params: OrganizationService.GetOrganizationsOrgIdUsersUserIdParams): __Observable<__StrictHttpResponse<{data?: Array<UserOrgBelonging>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${params.orgId}/users/${params.userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<UserOrgBelonging>}>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.GetOrganizationsOrgIdUsersUserIdParams` containing the following parameters:
   *
   * - `user_id`: the user
   *
   * - `org_id`: The uuid of the organization
   *
   * @return the relationship information of the org to the user
   */
  getOrganizationsOrgIdUsersUserId(params: OrganizationService.GetOrganizationsOrgIdUsersUserIdParams): __Observable<{data?: Array<UserOrgBelonging>}> {
    return this.getOrganizationsOrgIdUsersUserIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Array<UserOrgBelonging>})
    );
  }

  /**
   * @param params The `OrganizationService.PutOrganizationsOrgIdUsersUserIdParams` containing the following parameters:
   *
   * - `user_id`: the user
   *
   * - `org_id`: The uuid of the organization
   *
   * - `new_role`: New role of the user
   *
   * @return the updated relationship information of the org to the user
   */
  putOrganizationsOrgIdUsersUserIdResponse(params: OrganizationService.PutOrganizationsOrgIdUsersUserIdParams): __Observable<__StrictHttpResponse<{data?: Array<UserOrgBelonging>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.newRole;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${params.orgId}/users/${params.userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<UserOrgBelonging>}>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.PutOrganizationsOrgIdUsersUserIdParams` containing the following parameters:
   *
   * - `user_id`: the user
   *
   * - `org_id`: The uuid of the organization
   *
   * - `new_role`: New role of the user
   *
   * @return the updated relationship information of the org to the user
   */
  putOrganizationsOrgIdUsersUserId(params: OrganizationService.PutOrganizationsOrgIdUsersUserIdParams): __Observable<{data?: Array<UserOrgBelonging>}> {
    return this.putOrganizationsOrgIdUsersUserIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Array<UserOrgBelonging>})
    );
  }

  /**
   * @param params The `OrganizationService.DeleteOrganizationsOrgIdUsersUserIdParams` containing the following parameters:
   *
   * - `user_id`: the user
   *
   * - `org_id`: The uuid of the organization
   *
   * @return the user has been removed from the org
   */
  deleteOrganizationsOrgIdUsersUserIdResponse(params: OrganizationService.DeleteOrganizationsOrgIdUsersUserIdParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${params.orgId}/users/${params.userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.DeleteOrganizationsOrgIdUsersUserIdParams` containing the following parameters:
   *
   * - `user_id`: the user
   *
   * - `org_id`: The uuid of the organization
   *
   * @return the user has been removed from the org
   */
  deleteOrganizationsOrgIdUsersUserId(params: OrganizationService.DeleteOrganizationsOrgIdUsersUserIdParams): __Observable<{}> {
    return this.deleteOrganizationsOrgIdUsersUserIdResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module OrganizationService {

  /**
   * Parameters for putOrganizationsOrgId
   */
  export interface PutOrganizationsOrgIdParams {

    /**
     * The uuid of the organizations
     */
    orgId: string;

    /**
     * The org info to update.
     */
    org: Organization;
  }

  /**
   * Parameters for postOrganizationsOrgIdUsers
   */
  export interface PostOrganizationsOrgIdUsersParams {

    /**
     * The uuid of the organizations
     */
    orgId: string;
    invitationDetail: {user_email?: string, user_name?: string, is_admin?: boolean};
  }

  /**
   * Parameters for postOrganizationsOrgIdInvites
   */
  export interface PostOrganizationsOrgIdInvitesParams {

    /**
     * The uuid of the organizations
     */
    orgId: string;
    invitationDetail: {user_email?: string, user_name?: string, is_admin?: boolean};
  }

  /**
   * Parameters for deleteOrganizationsOrgIdInvitesInvitationId
   */
  export interface DeleteOrganizationsOrgIdInvitesInvitationIdParams {

    /**
     * The uuid of the organization
     */
    orgId: string;

    /**
     * the invitation id
     */
    invitationId: string;
  }

  /**
   * Parameters for getOrganizationsOrgIdUsersUserId
   */
  export interface GetOrganizationsOrgIdUsersUserIdParams {

    /**
     * the user
     */
    userId: string;

    /**
     * The uuid of the organization
     */
    orgId: string;
  }

  /**
   * Parameters for putOrganizationsOrgIdUsersUserId
   */
  export interface PutOrganizationsOrgIdUsersUserIdParams {

    /**
     * the user
     */
    userId: string;

    /**
     * The uuid of the organization
     */
    orgId: string;

    /**
     * New role of the user
     */
    newRole: {is_admin?: boolean};
  }

  /**
   * Parameters for deleteOrganizationsOrgIdUsersUserId
   */
  export interface DeleteOrganizationsOrgIdUsersUserIdParams {

    /**
     * the user
     */
    userId: string;

    /**
     * The uuid of the organization
     */
    orgId: string;
  }
}

export { OrganizationService }
