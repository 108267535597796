export interface ObjectMap<T> {
    [key: string]: T;
}

export function map2array<TInput, TValue>(
    map: ObjectMap<TInput>,
    valueSelector: (string, TInput) => TValue = ((k: string, t: TInput) => t as any as TValue)) {

    return Object.keys(map).map(key => valueSelector(key, map[key]));
}

export function array2map<T, TValue>(
    array: T[],
    keySelector: (item: T) => string,
    valueSelector?: (item: T) => TValue
): {[key: string]: any} {
    return array.reduce((result, item) => {
        result[keySelector(item)] = valueSelector ? valueSelector(item) : item;
        return result;
    }, {} as any);
}