import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ModalDialogModule } from 'ngx-modal-dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './shared/auth.interceptor';
import {UserDetailFormComponent} from './users/user-detail-form/user-detail-form.component';
import {DashboardHomeComponent} from './dashboard/dashboard-home.component';
import {LoginComponent} from './auth/login.component';
import {LogoutComponent} from './auth/logout.component';
import {SignUpComponent} from './auth/sign-up.component';
import {BaseModalComponent} from './modals/base-modal.component';
import {AwsAddComponent} from './modals/aws-add-account/awsadd-component';
import {AwsRemoveComponent} from './modals/aws-remove-account/awsremove-component';

import {
  MatButtonModule, 
  MatCardModule, MatDividerModule,
  MatFormFieldModule, MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule, MatSidenavModule, MatStepperModule, MatDialogModule
} from '@angular/material';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth-guard.service';
import {UserGuard} from './users/user-guard.service';
import {ReactiveFormsModule,FormsModule} from '@angular/forms';
import {CallbackComponent} from './callback/callback.component';
import {OrgDetailFormComponent} from './orgs/org-detail-form/org-detail-form.component';
import {OrgListComponent} from './orgs/org-list/org-list.component';
import {AvatarModule} from 'ngx-avatar';
import {OrgSelectComponent} from './orgs/org-select-card/org-select.component';
import {OrgLayoutComponent} from './modules/select-org/org-layout.component';
import {OrgAuthGuard} from './orgs/org-guard.service';
import {SignOnFlowComponent} from './sign-on-flow/sign-on-flow.component';
import {SidebarComponent} from './sidebar/sidebar-component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { OrgNavBarComponent } from './nav-bar/org-nav-bar.component';
import {UserWrapperService} from './shared/user.wrapper.service';
import {OrgWrapperService} from './shared/org.wrapper.service';
import {ModalWrapperService} from './shared/modal.wrapper.service';
import { ServicesModule } from './shared/services/services.module';
import { ComponentsModule } from './shared/components/components.module';
import { CardsWrapperService } from './shared/cards.wrapper.service';

@NgModule({
  declarations: [
    AppComponent,
    DashboardHomeComponent,
    UserDetailFormComponent,
    LoginComponent,
    SignUpComponent,
    CallbackComponent,
    OrgDetailFormComponent,
    OrgListComponent,
    OrgSelectComponent,
    OrgLayoutComponent,
    SignOnFlowComponent,
    SidebarComponent,
    LogoutComponent,
    NavBarComponent,
    OrgNavBarComponent,
    AwsAddComponent,
    BaseModalComponent,
    AwsRemoveComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatIntlTelInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatGridListModule,
    MatDividerModule,
    MatSidenavModule,
    MatDialogModule,
    AvatarModule,
    MatStepperModule,
    ModalDialogModule.forRoot(),
    FormsModule,
    ServicesModule,
    ComponentsModule,
  ],
  providers: [
    AuthService,
    UserWrapperService,
    OrgWrapperService,
    ModalWrapperService,
    CardsWrapperService,
    AuthGuard,
    UserGuard,
    OrgAuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BaseModalComponent,
    AwsAddComponent,
    AwsRemoveComponent
  ]
})
export class AppModule { }
