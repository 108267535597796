export function ascendingSorter(a: any, b: any) {
    const numericCompare = typeof a === 'number' || typeof b === 'number';
    const x = numericCompare ? a : `${a||''}`;
    const y = numericCompare ? b : `${b||''}`;
    return x < y ? -1 : x > y ? 1 : 0;
}

export function descendingSorter(a: any, b: any) {
    return -1 * ascendingSorter(a, b);
}

export function sortBy<T, TKey>(
    input: T[],
    selector: (t: T) => TKey,
    sorter: (a: TKey, b: TKey) => number = ascendingSorter
) {
    return input.sort((a, b) => sorter(selector(a), selector(b)));
}

export function groupBy<T>(
    input: T[],
    keySelector: (item: T) => string
): {[key: string]: T[]} {
    return input.reduce((result, item) => {
        result[keySelector(item)] = result[keySelector(item)] || [];
        result[keySelector(item)].push(item);
        return result;
    }, {});
}