import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { isAbsoluteUrl } from '../utils';

export type RequestHttpHeaders = HttpHeaders | {
    [header: string]: string | string[];
};

export type RequestHttpParams = HttpParams | {
    [param: string]: string | string[];
};

export interface RequestOptionsArgs {
    headers?: RequestHttpHeaders;
    params?: RequestHttpParams;
    withCredentials?: boolean;
}

@Injectable()
export class HttpService {

    constructor(
        protected _http: HttpClient,
    ) { }

    async get<T = any>(url: string, options?: RequestOptionsArgs) {
        return await this._http.get<T>(this._getUrl(url), options).toPromise();
    }

    async put<T = any>(url: string, body: any, options?: RequestOptionsArgs) {
        return await this._http.put<T>(this._getUrl(url), body, options).toPromise();
    }

    async patch<T = any>(url: string, body: any, options?: RequestOptionsArgs) {
        return await this._http.patch<T>(this._getUrl(url), body, options).toPromise();
    }

    async post<T = any>(url: string, body: any, options?: RequestOptionsArgs) {
        return await this._http.post<T>(this._getUrl(url), body, options).toPromise();
    }

    async delete<T = any>(url: string, options?: RequestOptionsArgs) {
        return await this._http.delete<T>(this._getUrl(url), options).toPromise();
    }

    protected _getUrl(url: string) {
        if (isAbsoluteUrl(url)) {
            return url;
        }

        return `${environment.apiRoot}${url}`;
    }

}
