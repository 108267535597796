import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {

    return this.authService.statusObservable().pipe(
      map(currentStatus => {
        if (currentStatus === false) {
          localStorage.setItem('authRedirect', state.url);
          console.log(state.url);
          this.authService.login();
        }
        return currentStatus;
      })
    );
  }
}
