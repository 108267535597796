import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';
import {OrgWrapperService} from '../../shared/org.wrapper.service';
import {Organization} from '../../ck-user-api/models/organization';


@Component({
  selector: 'app-org-list',
  templateUrl: './org-list.component.html',
  styleUrls: ['./org-list.component.scss']
})
export class OrgListComponent implements OnInit, OnDestroy {

  orgSubscription: Subscription;
  currentOrgs: Organization[] = [];

  constructor(
    private orgWrapperService: OrgWrapperService
  ) {
  }

  ngOnInit(): void {
    this.orgSubscription = this.orgWrapperService.userOrgsSubject.subscribe(
      (orgs: Organization[]) => {
        this.currentOrgs = orgs;
      }
    );
  }

  ngOnDestroy(): void {
    this.orgSubscription.unsubscribe();
  }

  selectOrg(orgId: string): void {
    this.orgWrapperService.setSelectedOrg(orgId);
  }

}
