import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserWrapperService} from '../shared/user.wrapper.service';


@Component({
    selector: 'app-dashboard-home',
    templateUrl: './dashboard-home.component.html',
    styleUrls: ['./dashboard-home.component.scss']
})
export class DashboardHomeComponent implements OnInit, OnDestroy {

  private isNewUser: boolean;

  constructor(
    private userWrapperService: UserWrapperService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
