import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';
import {MatStepper} from '@angular/material';
import {forEach} from '@angular/router/src/utils/collection';

import {Router} from '@angular/router';
import {AccountWrapperService} from '../../shared/account.wrapper.service';
import {OrgWrapperService} from '../../shared/org.wrapper.service';
import {Organization} from '../../ck-user-api/models/organization';

declare const $: any;

@Component({
  selector: 'app-org-select',
  templateUrl: './org-select.component.html',
  styleUrls: ['./org-select.component.scss']
})
export class OrgSelectComponent implements OnInit, OnDestroy {

  @Input() stepper: MatStepper;
  orgSubscription: Subscription;
  currentOrgs: Organization[] = [];
  errorPrompt = false;
  errorMessage = '';

  constructor(
    private router: Router,
    private orgWrapperService: OrgWrapperService,
    private accountWrapperService: AccountWrapperService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.currentOrgs = await this.orgWrapperService.getOrgs();
    this.orgSubscription = this.orgWrapperService.userOrgsSubject.subscribe(
      (orgs: Organization[]) => {
        this.currentOrgs = orgs;
      }
    );
  }

  ngOnDestroy(): void {
    this.orgSubscription.unsubscribe();
  }

  private showNotification(errorMessage) {
    $.notify({
      icon: 'notifications',
      message: errorMessage
    }, {
      type: 'warning',
      timer: 3000,
      placement: {
        from: 'top',
        align: 'center'
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  closeNotification(): void {
    this.errorPrompt = false;
  }

  async submit(): Promise<void> {

    if ( this.currentOrgs == null || this.currentOrgs.length === 0 ) {
      // no orgs
      this.errorPrompt = true;
      this.errorMessage = 'You haven\'t create any organization yet. Please click create organization to create one.';
      return;
    }
    for (const org of this.currentOrgs) {
      const orgAccounts = await this.accountWrapperService.getAccounts();
      if (orgAccounts == null || orgAccounts.length === 0) {
        this.errorPrompt = true;
        this.errorMessage = `You haven\'t link any account to \`${org.org_name}\` yet. Please link one account to it`;
        return;
      }
    }
    this.router.navigate(['/']);
  }

}
