/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Image } from '../models/image';
@Injectable({
  providedIn: 'root',
})
class ImagesService extends __BaseService {
  static readonly getOrganizationsCkOrgIdImagesPath = '/organizations/{ck_org_id}/images';
  static readonly getOrganizationsCkOrgIdImagesImageIdPath = '/organizations/{ck_org_id}/images/{image_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param ck_org_id CloudKeep organization id
   * @return list of AWS Images
   */
  getOrganizationsCkOrgIdImagesResponse(ckOrgId: string): __Observable<__StrictHttpResponse<{data?: Array<Image>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${ckOrgId}/images`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Image>}>;
      })
    );
  }
  /**
   * @param ck_org_id CloudKeep organization id
   * @return list of AWS Images
   */
  getOrganizationsCkOrgIdImages(ckOrgId: string): __Observable<{data?: Array<Image>}> {
    return this.getOrganizationsCkOrgIdImagesResponse(ckOrgId).pipe(
      __map(_r => _r.body as {data?: Array<Image>})
    );
  }

  /**
   * @param params The `ImagesService.GetOrganizationsCkOrgIdImagesImageIdParams` containing the following parameters:
   *
   * - `image_id`: AWS image id
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * @return An AWS Image info
   */
  getOrganizationsCkOrgIdImagesImageIdResponse(params: ImagesService.GetOrganizationsCkOrgIdImagesImageIdParams): __Observable<__StrictHttpResponse<{data?: Image}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${params.ckOrgId}/images/${params.imageId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Image}>;
      })
    );
  }
  /**
   * @param params The `ImagesService.GetOrganizationsCkOrgIdImagesImageIdParams` containing the following parameters:
   *
   * - `image_id`: AWS image id
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * @return An AWS Image info
   */
  getOrganizationsCkOrgIdImagesImageId(params: ImagesService.GetOrganizationsCkOrgIdImagesImageIdParams): __Observable<{data?: Image}> {
    return this.getOrganizationsCkOrgIdImagesImageIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Image})
    );
  }
}

module ImagesService {

  /**
   * Parameters for getOrganizationsCkOrgIdImagesImageId
   */
  export interface GetOrganizationsCkOrgIdImagesImageIdParams {

    /**
     * AWS image id
     */
    imageId: string;

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;
  }
}

export { ImagesService }
