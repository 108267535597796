import { Input, Component, OnChanges } from '@angular/core';

@Component({
    selector: 'chart-donut-small',
    templateUrl: './chart-donut-small.component.html',
    styleUrls: ['./chart-donut-small.component.scss'],
})
export class ChartDonutSmallComponent implements OnChanges {

    @Input() color1: string;
    @Input() color2: string;
    @Input() label: string;
    @Input() data: any[];

    chartData;
    chartLabels;
    chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        rotation: Math.PI/4,
        legend: false,
        animation: {
            animateRotate: false,
        },
        tooltips: {
            enabled: false,
        },
        striped: true,
    };

    ngOnChanges() {
        this._refresh();
    }

    private _refresh() {
        this.chartData = [{
            data: this.data.slice(),
            backgroundColor: [this.color1, this.color2],
            hoverBackgroundColor: [this.color1, this.color2], // Disable hover by setting the same color
            borderColor: [this.color1, this.color2],
            borderWidth: [1, 0],
        }];
        this.chartLabels = [];
    }

}