import {Injectable} from '@angular/core';
import {OrganizationService} from '../ck-user-api/services/organization.service';
import {Organization} from '../ck-user-api/models/organization';
import {JwtService} from '../ck-user-api/services/jwt.service';
import {UserService} from '../ck-user-api/services/user.service';
import {UserOrgBelonging} from '../ck-user-api/models/user-org-belonging';
import {AuthService} from '../auth/auth.service';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OrgWrapperService {

  public userOrgs = [
      {
        org_id: 'string',
        org_name: 'string',
        org_domain: 'string',
        start_date: 'string',
        end_date: 'string',
        properties: {},
        img: 'string',
        default: true,
      } as Organization,
    ];
  public selectedOrg = this.userOrgs[0];
  public orgMap: Map<string, Organization>;
  public selectedOrgSubject = new BehaviorSubject<Organization>(this.userOrgs[0]);
  public userOrgsSubject = new BehaviorSubject<Organization[]>(this.userOrgs);
  public ckVND: string;
  private expiredAt: number;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private orgService: OrganizationService,
    private jwtService: JwtService
  ) {
  }

  private generateOrgMap() {
    this.orgMap = new Map<string, Organization>();
    if (this.userOrgs != null) {
      for (const org of this.userOrgs) {
        this.orgMap[org.org_id] = org;
      }
    }
    return this.orgMap;
  }

  public async getCkVND(): Promise<string> {
    // const vnd = await this.jwtService.getTokens().toPromise();
    const vnd = {
      token: 'token',
      expires_in: 9999999,
    }
    this.ckVND = vnd.token;
    this.expiredAt = vnd.expires_in;
    // this.scheduleRenewal();
    return this.ckVND;
  }

  public async getOrgs(): Promise<Organization[]> {

    return this.userOrgs;
  }

  public async getUserOrgRel(orgId: string): Promise<UserOrgBelonging> {
    try {
      // const res = await this.orgService.getOrganizationsOrgIdUsersUserId({orgId, userId: this.authService.userId}).toPromise();
      // return res.data[0];
      return {
        user_id: 'string',
        org_id: orgId,
        role_type: 'string',
        default: true,
        joined_time: 'string',
        user: {},
      } as any;
    } catch (e) {
      return null;
    }
  }

  public async createNewOrganization(org: Organization): Promise<Organization> {
    try {
      const resp = await this.orgService.postOrganizations(org).toPromise();
      await this.getCkVND();
      await this.getOrgs();
      return resp.data[0];
    } catch (e) {
      return null;
    }
  }

  public setSelectedOrg(orgId: string) {
    return this.selectedOrg;
  }


}
