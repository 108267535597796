import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {MatStepper} from '@angular/material';

import {UserWrapperService} from '../../shared/user.wrapper.service';


@Component({
  selector: 'app-user-detail-form',
  templateUrl: './user-detail-form.component.html',
  styleUrls: ['./user-detail-form.component.scss']
})
export class UserDetailFormComponent implements OnInit, OnDestroy {

  @Input() stepper: MatStepper;

  constructor(
    private userWrapperService: UserWrapperService,
    private router: Router
  ) {
  }

  profileForm: FormGroup;

  public static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    // @ts-ignore
    this.profileForm = new FormGroup({
      firstName: new FormControl(
        this.userWrapperService.user.first_name,
        [Validators.required, UserDetailFormComponent.noWhitespaceValidator]
      ),
      lastName: new FormControl(
        this.userWrapperService.user.last_name,
        [Validators.required, UserDetailFormComponent.noWhitespaceValidator]
      ),
      phoneNumber: new FormControl(this.userWrapperService.user.properties.phone, Validators.required)
    });
  }

  async submit(): Promise<void> {
    const newUser = {
      first_name: this.profileForm.value.firstName.trim(),
      last_name: this.profileForm.value.last_name.trim(),
      properties: {
        phone: this.profileForm.value.phoneNumber
      }
    };

    await this.userWrapperService.updateUser(newUser);
    if (this.stepper != null) {
      this.stepper.next();
    } else {
      this.router.navigate(['/']);
    }
  }

}
