/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Instance } from '../models/instance';
@Injectable({
  providedIn: 'root',
})
class InstancesService extends __BaseService {
  static readonly getOrganizationsCkOrgIdInstancesPath = '/organizations/{ck_org_id}/instances';
  static readonly getOrganizationsCkOrgIdInstancesInstanceIdPath = '/organizations/{ck_org_id}/instances/{instance_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param ck_org_id CloudKeep organization id
   * @return list of AWS Instances
   */
  getOrganizationsCkOrgIdInstancesResponse(ckOrgId: string): __Observable<__StrictHttpResponse<{data?: Array<Instance>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${ckOrgId}/instances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Instance>}>;
      })
    );
  }
  /**
   * @param ck_org_id CloudKeep organization id
   * @return list of AWS Instances
   */
  getOrganizationsCkOrgIdInstances(ckOrgId: string): __Observable<{data?: Array<Instance>}> {
    return this.getOrganizationsCkOrgIdInstancesResponse(ckOrgId).pipe(
      __map(_r => _r.body as {data?: Array<Instance>})
    );
  }

  /**
   * @param params The `InstancesService.GetOrganizationsCkOrgIdInstancesInstanceIdParams` containing the following parameters:
   *
   * - `instance_id`: AWS instance id
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * @return An AWS Instance info
   */
  getOrganizationsCkOrgIdInstancesInstanceIdResponse(params: InstancesService.GetOrganizationsCkOrgIdInstancesInstanceIdParams): __Observable<__StrictHttpResponse<{data?: Instance}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${params.ckOrgId}/instances/${params.instanceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Instance}>;
      })
    );
  }
  /**
   * @param params The `InstancesService.GetOrganizationsCkOrgIdInstancesInstanceIdParams` containing the following parameters:
   *
   * - `instance_id`: AWS instance id
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * @return An AWS Instance info
   */
  getOrganizationsCkOrgIdInstancesInstanceId(params: InstancesService.GetOrganizationsCkOrgIdInstancesInstanceIdParams): __Observable<{data?: Instance}> {
    return this.getOrganizationsCkOrgIdInstancesInstanceIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Instance})
    );
  }
}

module InstancesService {

  /**
   * Parameters for getOrganizationsCkOrgIdInstancesInstanceId
   */
  export interface GetOrganizationsCkOrgIdInstancesInstanceIdParams {

    /**
     * AWS instance id
     */
    instanceId: string;

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;
  }
}

export { InstancesService }
