import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import { UserWrapperService } from '../shared/user.wrapper.service';
import { User } from '../ck-user-api/models/user';
import {OrgWrapperService} from '../shared/org.wrapper.service';

@Injectable()
export class UserGuard implements CanActivate {

  constructor(
    private userWrapperService: UserWrapperService,
    private orgWrapperService: OrgWrapperService
  ) {
  }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const currentUser: User = await this.userWrapperService.userLogIn();
    // force to get ck-vnd;
    await this.orgWrapperService.getCkVND();
    return currentUser != null;
  }
}
