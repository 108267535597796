import {Component, OnDestroy, OnInit,ComponentRef,OnChanges} from '@angular/core';
import {AccountWrapperService} from '../../shared/account.wrapper.service';
import {Account} from '../../ck-aws-api/models/account';
import {interval, Subscription} from 'rxjs';
import {ModalWrapperService} from '../../shared/modal.wrapper.service';

@Component({
  selector: 'app-modal-awsadd',
  templateUrl: './awsadd-component.html',
  inputs :['options']
})
export class AwsAddComponent {
  public awsLink;
  private id;
  private options;
  public spinWorking = false;
  private accounts:Account[];
  private updateAccountSubscription: Subscription;

  constructor(
    private accountService: AccountWrapperService,
    private modalWrapperService:ModalWrapperService
    ) {
  }

  ngOnInit(){
    if(this.options){
      this.awsLink = this.options.awsLink;
      this.id = this.options.id;
    }
  }

  ngOnChanges(): void {
    if(this.options){
      this.awsLink = this.options.awsLink;
      this.id = this.options.id;
    }
  }

  ngOnDestroy(){
    if (this.updateAccountSubscription != null) {
      this.updateAccountSubscription.unsubscribe();
    }
  }

  async waitForBuild(): Promise<void> {
    let scope = this;
    scope.spinWorking = true;
    this.accountService.getAccounts().then(function(acnts){
      scope.accounts = acnts;
      scope.autoUpdate();      
    })
  }

  closeMe(){
    this.options.closeDialogSubject.next();
    this.modalWrapperService.closeModal();
  }

  private autoUpdate(): void { 
    if (this.updateAccountSubscription != null) {
      this.updateAccountSubscription.unsubscribe();
    }
    const source = interval(30000);
    let cntMax = 5*2;//wait for 5mins
    const prvAcntData = this.accounts?JSON.stringify(this.accounts):"";
    this.updateAccountSubscription = source.subscribe(
      async (val) => {
        const accounts: Account[] = await this.accountService.getAccounts();
        if (accounts != null) {
          this.accounts = accounts;
          cntMax--;
          if(prvAcntData!=JSON.stringify(this.accounts) || cntMax==0){
            this.updateAccountSubscription.unsubscribe();
            this.spinWorking = false;
            this.closeMe();
          }
        }
      }
    );
  }
}

