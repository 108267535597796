// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientID: 'hJiT7pSsp7q6qc7TvevNXtwASKUtAQIO',
  domain: 'auth.cloudkeep.io',
  redirectUri: 'http://localhost:3000/callback',
  audience: 'api.cloudkeep.io',
  logoutReturnUri: 'http://localhost:3000',
  awsRegion: 'us-east-1',
  awsBucketName: 'ckrepo-cf-registration-us-east-1',
  awsTemplateURL: 'https://s3.amazonaws.com/ckrepo-cf-registration-us-east-1/cloudkeep_014.yml',
  awsStackName: 'CloudKeep-v014',
  awsCloudFormationURL: 'https://console.aws.amazon.com/cloudformation/home',
  tenant: 'CloudKeep',
  tokenIssuer: 'https://auth.cloudkeep.io/',
  apiRoot: '/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
