import { NgModule } from '@angular/core';
import {Routes, RouterModule, Router, NavigationEnd, PreloadAllModules} from '@angular/router';
import {UserDetailFormComponent} from './users/user-detail-form/user-detail-form.component';
import {DashboardHomeComponent} from './dashboard/dashboard-home.component';
import {AuthGuard} from './auth/auth-guard.service';
import {UserGuard} from './users/user-guard.service';
import {SignUpComponent} from './auth/sign-up.component';
import {LoginComponent} from './auth/login.component';
import {LogoutComponent} from './auth/logout.component';
import {CallbackComponent} from './callback/callback.component';
import {OrgDetailFormComponent} from './orgs/org-detail-form/org-detail-form.component';
import {OrgLayoutComponent} from './modules/select-org/org-layout.component';
import {OrgAuthGuard} from './orgs/org-guard.service';
import {SignOnFlowComponent} from './sign-on-flow/sign-on-flow.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '',
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        // canActivate: [OrgAuthGuard],
        children: [
          {
            path: '',
            loadChildren: './modules/select-org/org-layout.module#OrgLayoutModule',
            component: OrgLayoutComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
     if (event instanceof NavigationEnd) {
       (<any>window).ga('set', 'page', event.urlAfterRedirects);
       (<any>window).ga('send', 'pageview');
     }
   });
 }

}
