import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../ck-user-api/services/user.service';
import { User } from '../ck-user-api/models/user';


@Injectable({
  providedIn: 'root'
})
export class UserWrapperService {

  public user: User;

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}

  public async userLogIn(): Promise<User> {
    try {
      this.user = {
        user_id: 'string',
        email: 'string',
        first_name: 'string',
        last_name: 'string',
        img: 'string',
        last_login: 'string',
        register_method: 'string',
        properties: {}
      } as any;
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === 404) {
          const resp = await this.userService.postUsers().toPromise();
          this.user = resp.data[0];
        }
        console.error('something went wrong');
      }
    }
    return this.user;
  }

  public async updateUser(newUser: User): Promise<User> {
    const resp = await this.userService.putUsersUserId({userId: this.authService.sub, user: newUser}).toPromise();
    this.user = resp.data[0];
    return this.user;
  }

}
