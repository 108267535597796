/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly getUsersUserIdPath = '/users/{user_id}';
  static readonly putUsersUserIdPath = '/users/{user_id}';
  static readonly deleteUsersUserIdPath = '/users/{user_id}';
  static readonly postUsersPath = '/users';
  static readonly putLoginPath = '/login';
  static readonly putLogoutPath = '/logout';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param user_id undefined
   * @return get user successfully
   */
  getUsersUserIdResponse(userId: string): __Observable<__StrictHttpResponse<{data?: Array<User>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<User>}>;
      })
    );
  }
  /**
   * @param user_id undefined
   * @return get user successfully
   */
  getUsersUserId(userId: string): __Observable<{data?: Array<User>}> {
    return this.getUsersUserIdResponse(userId).pipe(
      __map(_r => _r.body as {data?: Array<User>})
    );
  }

  /**
   * @param params The `UserService.PutUsersUserIdParams` containing the following parameters:
   *
   * - `user_id`:
   *
   * - `user`: The user info to update.
   *
   * @return get user successfully
   */
  putUsersUserIdResponse(params: UserService.PutUsersUserIdParams): __Observable<__StrictHttpResponse<{data?: Array<User>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.user;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/${params.userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<User>}>;
      })
    );
  }
  /**
   * @param params The `UserService.PutUsersUserIdParams` containing the following parameters:
   *
   * - `user_id`:
   *
   * - `user`: The user info to update.
   *
   * @return get user successfully
   */
  putUsersUserId(params: UserService.PutUsersUserIdParams): __Observable<{data?: Array<User>}> {
    return this.putUsersUserIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Array<User>})
    );
  }

  /**
   * @param user_id undefined
   */
  deleteUsersUserIdResponse(userId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/${userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param user_id undefined
   */
  deleteUsersUserId(userId: string): __Observable<null> {
    return this.deleteUsersUserIdResponse(userId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return create user successfully
   */
  postUsersResponse(): __Observable<__StrictHttpResponse<{data?: Array<User>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<User>}>;
      })
    );
  }
  /**
   * @return create user successfully
   */
  postUsers(): __Observable<{data?: Array<User>}> {
    return this.postUsersResponse().pipe(
      __map(_r => _r.body as {data?: Array<User>})
    );
  }

  /**
   * @return update user login info successfully
   */
  putLoginResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return update user login info successfully
   */
  putLogin(): __Observable<{}> {
    return this.putLoginResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return update user login info successfully
   */
  putLogoutResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return update user login info successfully
   */
  putLogout(): __Observable<{}> {
    return this.putLogoutResponse().pipe(
      __map(_r => _r.body as {})
    );
  }
}

module UserService {

  /**
   * Parameters for putUsersUserId
   */
  export interface PutUsersUserIdParams {
    userId: string;

    /**
     * The user info to update.
     */
    user: User;
  }
}

export { UserService }
