/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Region } from '../models/region';
@Injectable({
  providedIn: 'root',
})
class RegionsService extends __BaseService {
  static readonly getRegionsPath = '/regions';
  static readonly getRegionsRegionIdPath = '/regions/{region_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return list of AWS Regions
   */
  getRegionsResponse(): __Observable<__StrictHttpResponse<{data?: Array<Region>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Region>}>;
      })
    );
  }
  /**
   * @return list of AWS Regions
   */
  getRegions(): __Observable<{data?: Array<Region>}> {
    return this.getRegionsResponse().pipe(
      __map(_r => _r.body as {data?: Array<Region>})
    );
  }

  /**
   * @param region_id AWS region name (e.g., 'us-east-1')
   * @return An AWS Region info
   */
  getRegionsRegionIdResponse(regionId: string): __Observable<__StrictHttpResponse<{data?: Region}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/regions/${regionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Region}>;
      })
    );
  }
  /**
   * @param region_id AWS region name (e.g., 'us-east-1')
   * @return An AWS Region info
   */
  getRegionsRegionId(regionId: string): __Observable<{data?: Region}> {
    return this.getRegionsRegionIdResponse(regionId).pipe(
      __map(_r => _r.body as {data?: Region})
    );
  }
}

module RegionsService {
}

export { RegionsService }
