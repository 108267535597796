/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Registration } from '../models/registration';
import { RegistrationPost } from '../models/registration-post';
import { RegistrationPut } from '../models/registration-put';
@Injectable({
  providedIn: 'root',
})
class RegistrationsService extends __BaseService {
  static readonly getOrganizationsCkOrgIdAccountsAccountIdRegistrationsPath = '/organizations/{ck_org_id}/accounts/{account_id}/registrations';
  static readonly postOrganizationsCkOrgIdAccountsAccountIdRegistrationsPath = '/organizations/{ck_org_id}/accounts/{account_id}/registrations';
  static readonly deleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdPath = '/organizations/{ck_org_id}/accounts/{account_id}/registrations/{registration_id}';
  static readonly getOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdPath = '/organizations/{ck_org_id}/accounts/{account_id}/registrations/{registration_id}';
  static readonly putOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdPath = '/organizations/{ck_org_id}/accounts/{account_id}/registrations/{registration_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RegistrationsService.GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS Account Registrations info
   */
  getOrganizationsCkOrgIdAccountsAccountIdRegistrationsResponse(params: RegistrationsService.GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams): __Observable<__StrictHttpResponse<{data?: Array<Registration>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/registrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Registration>}>;
      })
    );
  }
  /**
   * @param params The `RegistrationsService.GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS Account Registrations info
   */
  getOrganizationsCkOrgIdAccountsAccountIdRegistrations(params: RegistrationsService.GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams): __Observable<{data?: Array<Registration>}> {
    return this.getOrganizationsCkOrgIdAccountsAccountIdRegistrationsResponse(params).pipe(
      __map(_r => _r.body as {data?: Array<Registration>})
    );
  }

  /**
   * @param params The `RegistrationsService.PostOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `registration_info`: new AWS registration information
   *
   * @return Created AWS Account Registration info
   */
  postOrganizationsCkOrgIdAccountsAccountIdRegistrationsResponse(params: RegistrationsService.PostOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams): __Observable<__StrictHttpResponse<{data?: Registration}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.registrationInfo;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/registrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Registration}>;
      })
    );
  }
  /**
   * @param params The `RegistrationsService.PostOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `registration_info`: new AWS registration information
   *
   * @return Created AWS Account Registration info
   */
  postOrganizationsCkOrgIdAccountsAccountIdRegistrations(params: RegistrationsService.PostOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams): __Observable<{data?: Registration}> {
    return this.postOrganizationsCkOrgIdAccountsAccountIdRegistrationsResponse(params).pipe(
      __map(_r => _r.body as {data?: Registration})
    );
  }

  /**
   * @param params The `RegistrationsService.DeleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams` containing the following parameters:
   *
   * - `registration_id`: registration id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   */
  deleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdResponse(params: RegistrationsService.DeleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/registrations/${params.registrationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RegistrationsService.DeleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams` containing the following parameters:
   *
   * - `registration_id`: registration id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   */
  deleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationId(params: RegistrationsService.DeleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams): __Observable<null> {
    return this.deleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `RegistrationsService.GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams` containing the following parameters:
   *
   * - `registration_id`: registration id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS Account Registration info
   */
  getOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdResponse(params: RegistrationsService.GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams): __Observable<__StrictHttpResponse<{data?: Registration}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/registrations/${params.registrationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Registration}>;
      })
    );
  }
  /**
   * @param params The `RegistrationsService.GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams` containing the following parameters:
   *
   * - `registration_id`: registration id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS Account Registration info
   */
  getOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationId(params: RegistrationsService.GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams): __Observable<{data?: Registration}> {
    return this.getOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Registration})
    );
  }

  /**
   * @param params The `RegistrationsService.PutOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams` containing the following parameters:
   *
   * - `registration_id`: registration id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `registration_info`: updated AWS Account Registration information
   *
   * @return Updated AWS Registration info
   */
  putOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdResponse(params: RegistrationsService.PutOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams): __Observable<__StrictHttpResponse<{data?: Registration}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    __body = params.registrationInfo;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}/registrations/${params.registrationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Registration}>;
      })
    );
  }
  /**
   * @param params The `RegistrationsService.PutOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams` containing the following parameters:
   *
   * - `registration_id`: registration id (e.g., 'uuid-uuid-uuiduuid')
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `registration_info`: updated AWS Account Registration information
   *
   * @return Updated AWS Registration info
   */
  putOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationId(params: RegistrationsService.PutOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams): __Observable<{data?: Registration}> {
    return this.putOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Registration})
    );
  }
}

module RegistrationsService {

  /**
   * Parameters for getOrganizationsCkOrgIdAccountsAccountIdRegistrations
   */
  export interface GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams {

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;
  }

  /**
   * Parameters for postOrganizationsCkOrgIdAccountsAccountIdRegistrations
   */
  export interface PostOrganizationsCkOrgIdAccountsAccountIdRegistrationsParams {

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;

    /**
     * new AWS registration information
     */
    registrationInfo?: RegistrationPost;
  }

  /**
   * Parameters for deleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationId
   */
  export interface DeleteOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams {

    /**
     * registration id (e.g., 'uuid-uuid-uuiduuid')
     */
    registrationId: string;

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;
  }

  /**
   * Parameters for getOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationId
   */
  export interface GetOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams {

    /**
     * registration id (e.g., 'uuid-uuid-uuiduuid')
     */
    registrationId: string;

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;
  }

  /**
   * Parameters for putOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationId
   */
  export interface PutOrganizationsCkOrgIdAccountsAccountIdRegistrationsRegistrationIdParams {

    /**
     * registration id (e.g., 'uuid-uuid-uuiduuid')
     */
    registrationId: string;

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;

    /**
     * updated AWS Account Registration information
     */
    registrationInfo?: RegistrationPut;
  }
}

export { RegistrationsService }
