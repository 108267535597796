import {Injectable} from '@angular/core';
import {OrgWrapperService} from './org.wrapper.service';
import {environment} from '../../environments/environment';
import { AccountsService} from '../ck-aws-api/services/accounts.service';
import { Account } from '../ck-aws-api/models/account';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Organization} from '../ck-user-api/models/organization';
import { Utils } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class AccountWrapperService {

  public accountsOfOrg: Account[];
  private orgSubscription: Subscription;

  constructor(
    private accountService: AccountsService,
    private orgWrapperService: OrgWrapperService
  ) {
    this.orgSubscription = this.orgWrapperService.selectedOrgSubject.pipe(
      filter( (org, ind) => org != null)
    ).subscribe(
      async (org: Organization) => await this.setNewOrg()
    );
  }
  private async setNewOrg(): Promise<void> {
    await this.getAccounts();
  }

  public generateAWSAccountURL(): string {
    const env = Utils.getAbsoluteDomainUrl().match(/console\.(.*\.)cloudkeep\.io/);
    var envStr = (env ? "-"+env[1].slice(0,-1) : "");
    return `${environment.awsCloudFormationURL.replace("ckrepo-cf-registration", "ckrepo-cf-registration"+envStr)}?\
region=${environment.awsRegion}#/stacks/create/review?\
templateURL=${environment.awsTemplateURL}&stackName=${environment.awsStackName}\
&param_CKOrgId=${this.orgWrapperService.selectedOrg.org_id}`;
  }

  public async getAccounts(): Promise<Account[]> {
    // const {data} = await this.accountService
    //   .getOrganizationsCkOrgIdAccounts(ckOrgId)
    //   .toPromise();
    const data = [
      {
        account_id: 'string',
        alias: 'string',
        ck_org_id: 'string',
        last_scan: {},
        registration_info: {},
      } as any as Account,
    ];

    this.accountsOfOrg = data;
    return this.accountsOfOrg;
  }

  public async deleteAccount(accountId): Promise<Account[]> {

    const ckOrgId = this.orgWrapperService.selectedOrg.org_id;
    const payload = await this.accountService
      .deleteOrganizationsCkOrgIdAccountsAccountId({accountId, ckOrgId})
      .toPromise();

    return payload;
  }

}
