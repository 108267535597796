import { Injectable } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {Router} from '@angular/router';
import {
  BehaviorSubject,
  Subscription,
  of,
  timer, Observable,
} from 'rxjs';
import {catchError, filter, first, map, mergeMap} from 'rxjs/operators';

import * as auth0 from 'auth0-js';

import { AUTH_CONFIG } from './auth-config';
import { Utils } from '../shared/utils';
import {take} from 'rxjs-compat/operator/take';

@Injectable()
export class AuthService {

  private auth0WebAuth = new auth0.WebAuth({
    clientID: AUTH_CONFIG.CLIENT_ID,
    domain: AUTH_CONFIG.CLIENT_DOMAIN,
    responseType: 'token id_token',
    redirectUri: AUTH_CONFIG.REDIRECT,
    audience: AUTH_CONFIG.AUDIENCE,
		scope: AUTH_CONFIG.SCOPE,
    overrides: {
      __tenant: AUTH_CONFIG.TENANT,
      __token_issuer: AUTH_CONFIG.TOKEN_ISSUER
    },
  });

  private accessTokenPrivate: string;
  private idTokenPrivate: string;
  private expiresAt: number;
  private subPrivate: string;
  userProfile: any;

  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean;
  loggedInSubject = new BehaviorSubject<boolean>(this.loggedIn);
  // Subscribe to token expiration stream
  refreshSub: Subscription;


  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    const status = this.isAuthenticated();
    this.setLogin(status);
  }

  get accessToken(): string {
    return this.accessTokenPrivate;
  }

  get authHeader(): string {
      return `Bearer ${this.accessToken}`;
  }

  get sub(): string {
    return this.subPrivate;
  }

  public login(): void {
    this.auth0WebAuth.authorize();
  }

  public signUp(): void {
    this.auth0WebAuth.authorize({
      login_hint: ''
    });
  }

  public logout(): void {
    this.clearRedirect();
    localStorage.removeItem('expires_at');
    this.accessTokenPrivate = null;
    this.idTokenPrivate = null;
    this.expiresAt = null;
    this.setLogin(false);
    this.unsubscribeRenewal();
    this.auth0WebAuth.logout({
      returnTo: Utils.getAbsoluteDomainUrl()
    });
  }

  public handleAuthentication(): void {
    
        this.localLogin({
          expiresIn: 99999,
          accessToken: 'accessToken',
          idToken: 'idToken',
          idTokenPayload: {
            sub: 'sub',
            
          }
        });
    
            this.router.navigate(['/']);
  }

  public renewTokens(): void {
    this.auth0WebAuth.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.localLogin(authResult);
      } else if (err) {
        console.error(`failed to get new token ${err.error}: ${err.error_description}).`);
        this.logout();
      }
    });
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) { return; }

    this.unsubscribeRenewal();

    const expiresIn = of(this.expiresAt).pipe(
      mergeMap(
        expiresAt => {
          const now = Date.now();
          // Use timer to track delay until expiration
          // to run the refresh at the proper time
          return timer(Math.max(1, expiresAt - now));
        }
      )
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSub = expiresIn.subscribe(
      () => {
        this.renewTokens();
      }
    );
  }

  public isAuthenticated(): boolean {
    return true;
  }
  public statusObservable(): Observable<boolean> {
    return this.loggedInSubject.pipe(
      filter((status, ind) => status != null),
    );
  }

  private unsubscribeRenewal(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

  private setLogin(status: boolean): void {
    this.loggedIn = status;
    this.loggedInSubject.next(status);
  }

  private localLogin(authResult): void {

    const expiresAt = (authResult.expiresIn * 1000) + Date.now();
    localStorage.setItem('expires_at',  JSON.stringify(expiresAt));
    this.accessTokenPrivate = authResult.accessToken;
    this.idTokenPrivate = authResult.idToken;

    this.userProfile = authResult.idTokenPayload;
    this.subPrivate = this.userProfile.sub;

    this.expiresAt = expiresAt;
    this.setLogin(true);
    this.scheduleRenewal();
    this.clearRedirect();
  }

  private clearRedirect() {
    // Remove redirect from localStorage
    localStorage.removeItem('authRedirect');
  }


}
