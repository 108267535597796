// import {OrgService} from '../org.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OrgWrapperService} from '../../shared/org.wrapper.service';
import {Organization} from '../../ck-user-api/models/organization';


@Component({
  selector: 'app-org-detail-form',
  templateUrl: './org-detail-form.component.html',
  styleUrls: ['./org-detail-form.component.scss']
})
export class OrgDetailFormComponent implements OnInit, OnDestroy {

  constructor(
    private orgWrapperService: OrgWrapperService,
    private router: Router
  ) {
  }

  orgForm: FormGroup;

  public static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.orgForm = new FormGroup({
      companyName: new FormControl('', [Validators.required, OrgDetailFormComponent.noWhitespaceValidator]),
      companyDomain: new FormControl('', [Validators.required, OrgDetailFormComponent.noWhitespaceValidator])
    });
  }
  private async createOrg(): Promise<void> {
    const newOrg: Organization = {
      org_name: this.orgForm.value.companyName,
      org_domain: this.orgForm.value.companyDomain
    };

    const org: Organization = await this.orgWrapperService.createNewOrganization(newOrg);
  }

  submit(): void {
    this.createOrg();
  }

}
