import {Injectable} from '@angular/core';
import { ModalService} from '../modals/base-modal.service';
import { BaseModalComponent } from '../modals/base-modal.component';
import {Subscription} from 'rxjs';
import {ModalDialogService} from 'ngx-modal-dialog';
import {filter} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalWrapperService {

  private isModalOpen = false;
  private closeFnc;

  constructor(
    private modalCustomService: ModalService,
    public modalDialogService: ModalDialogService,
  ) {
  }

  public openModal(modalParam){
    console.log(this.isModalOpen);
    var classes = modalParam.classes?modalParam.classes:{};
    if(!this.isModalOpen){
      var t1 = this.modalDialogService.openDialog(modalParam.viewRef, {
        title: modalParam.title, 
        childComponent: BaseModalComponent,
        onClose:modalParam.onClose,
        settings:{modalClass: "appModal",...classes},
        data:{...modalParam.data,insideComponent:modalParam.insideComponent}
      });
      this.closeFnc = modalParam.onClose;
      this.isModalOpen = true;
    }
  }

  public closeModal(){
    if(this.closeFnc)
      this.closeFnc();
    this.isModalOpen = false;
    console.log(this.isModalOpen);
  }

}
