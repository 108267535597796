import { NgModule } from '@angular/core';

import { HttpService } from './http.service';
import { HttpMockupService } from './http-mockup.service';

@NgModule({
    providers: [
        HttpService,
        HttpMockupService,
    ],
})
export class ServicesModule {}
