import { Chart } from 'chart.js';

const StripedDoughnut = Chart.helpers.clone(Chart.defaults.doughnut);
const StripedDoughnutCtrl = Chart.controllers.doughnut.extend({
    draw: function (ease) {
        var ctx = this.chart.chart.ctx;

        var easingDecimal = ease || 1;
        Chart.helpers.each(this.getMeta().data, function (chartElem, index) {
            chartElem.transition(easingDecimal).draw();

            var options = chartElem._chart.options;
            if (options.striped) {

                var view = chartElem._view;
                var radius = (view.outerRadius + view.innerRadius) / 2;
                var thickness = (view.outerRadius - view.innerRadius) / 2;
                var angle = Math.PI - view.endAngle - Math.PI / 2;

                ctx.save();

                ctx.fillStyle = view.backgroundColor;
                ctx.translate(view.x, view.y);
                ctx.beginPath();
                ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
                ctx.closePath();
                ctx.fill();

                if (index === 0) {
                    var originX = 0, originY = 0, curX, curY;
                    for (var degree = -5; degree <= 360; degree += 13) {
                        var angleOffset = Math.PI*degree/180;
                        curX = radius * Math.sin(angle + angleOffset);
                        curY = radius * Math.cos(angle + angleOffset);
                        ctx.translate(curX - originX, curY - originY);
                        ctx.rotate(Math.PI/2-(angle + angleOffset));
                        
                        ctx.clearRect(-1.1*thickness, -0.5*thickness, 2*1.1*thickness, thickness);
                        
                        ctx.rotate(-Math.PI/2+(angle + angleOffset));
                        ctx.fill();
                        originX = curX;
                        originY = curY;
                    }
                }
            }

            ctx.restore();
        });
    },
});

// Override 'doughnut' chart type
Chart.defaults.doughnut = StripedDoughnut;
Chart.controllers.doughnut = StripedDoughnutCtrl;