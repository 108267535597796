import { environment } from '../../environments/environment';
import { Utils } from '../shared/utils';

interface AuthConfig {
    CLIENT_ID: string;
    CLIENT_DOMAIN: string;
    AUDIENCE: string;
    REDIRECT: string;
    SCOPE: string;
    TENANT: string;
    TOKEN_ISSUER: string;
}

export const AUTH_CONFIG: AuthConfig = {
    CLIENT_ID: environment.clientID,
    CLIENT_DOMAIN: environment.domain,
    AUDIENCE: environment.audience,
    REDIRECT: Utils.getAbsoluteDomainUrl() + '/callback',
    SCOPE: 'openid profile email',
    TENANT: environment.tenant,
    TOKEN_ISSUER: environment.tokenIssuer
};
