/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Account } from '../models/account';
import { AccountPost } from '../models/account-post';
import { AccountPut } from '../models/account-put';
@Injectable({
  providedIn: 'root',
})
class AccountsService extends __BaseService {
  static readonly getOrganizationsCkOrgIdAccountsPath = '/organizations/{ck_org_id}/accounts';
  static readonly postOrganizationsCkOrgIdAccountsPath = '/organizations/{ck_org_id}/accounts';
  static readonly deleteOrganizationsCkOrgIdAccountsAccountIdPath = '/organizations/{ck_org_id}/accounts/{account_id}';
  static readonly getOrganizationsCkOrgIdAccountsAccountIdPath = '/organizations/{ck_org_id}/accounts/{account_id}';
  static readonly putOrganizationsCkOrgIdAccountsAccountIdPath = '/organizations/{ck_org_id}/accounts/{account_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param ck_org_id CloudKeep organization id
   * @return Retrieved AWS accounts info
   */
  getOrganizationsCkOrgIdAccountsResponse(ckOrgId: string): __Observable<__StrictHttpResponse<{data?: Array<Account>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${ckOrgId}/accounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Array<Account>}>;
      })
    );
  }
  /**
   * @param ck_org_id CloudKeep organization id
   * @return Retrieved AWS accounts info
   */
  getOrganizationsCkOrgIdAccounts(ckOrgId: string): __Observable<{data?: Array<Account>}> {
    return this.getOrganizationsCkOrgIdAccountsResponse(ckOrgId).pipe(
      __map(_r => _r.body as {data?: Array<Account>})
    );
  }

  /**
   * @param params The `AccountsService.PostOrganizationsCkOrgIdAccountsParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_info`: new AWS account information
   *
   * @return Created AWS account info
   */
  postOrganizationsCkOrgIdAccountsResponse(params: AccountsService.PostOrganizationsCkOrgIdAccountsParams): __Observable<__StrictHttpResponse<{data?: Account}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.accountInfo;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Account}>;
      })
    );
  }
  /**
   * @param params The `AccountsService.PostOrganizationsCkOrgIdAccountsParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_info`: new AWS account information
   *
   * @return Created AWS account info
   */
  postOrganizationsCkOrgIdAccounts(params: AccountsService.PostOrganizationsCkOrgIdAccountsParams): __Observable<{data?: Account}> {
    return this.postOrganizationsCkOrgIdAccountsResponse(params).pipe(
      __map(_r => _r.body as {data?: Account})
    );
  }

  /**
   * @param params The `AccountsService.DeleteOrganizationsCkOrgIdAccountsAccountIdParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   */
  deleteOrganizationsCkOrgIdAccountsAccountIdResponse(params: AccountsService.DeleteOrganizationsCkOrgIdAccountsAccountIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AccountsService.DeleteOrganizationsCkOrgIdAccountsAccountIdParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   */
  deleteOrganizationsCkOrgIdAccountsAccountId(params: AccountsService.DeleteOrganizationsCkOrgIdAccountsAccountIdParams): __Observable<null> {
    return this.deleteOrganizationsCkOrgIdAccountsAccountIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AccountsService.GetOrganizationsCkOrgIdAccountsAccountIdParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS account info
   */
  getOrganizationsCkOrgIdAccountsAccountIdResponse(params: AccountsService.GetOrganizationsCkOrgIdAccountsAccountIdParams): __Observable<__StrictHttpResponse<{data?: Account}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Account}>;
      })
    );
  }
  /**
   * @param params The `AccountsService.GetOrganizationsCkOrgIdAccountsAccountIdParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * @return Retrieved AWS account info
   */
  getOrganizationsCkOrgIdAccountsAccountId(params: AccountsService.GetOrganizationsCkOrgIdAccountsAccountIdParams): __Observable<{data?: Account}> {
    return this.getOrganizationsCkOrgIdAccountsAccountIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Account})
    );
  }

  /**
   * @param params The `AccountsService.PutOrganizationsCkOrgIdAccountsAccountIdParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `account_info`: updated AWS account information
   *
   * @return Updated AWS account info
   */
  putOrganizationsCkOrgIdAccountsAccountIdResponse(params: AccountsService.PutOrganizationsCkOrgIdAccountsAccountIdParams): __Observable<__StrictHttpResponse<{data?: Account}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.accountInfo;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${params.ckOrgId}/accounts/${params.accountId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{data?: Account}>;
      })
    );
  }
  /**
   * @param params The `AccountsService.PutOrganizationsCkOrgIdAccountsAccountIdParams` containing the following parameters:
   *
   * - `ck_org_id`: CloudKeep organization id
   *
   * - `account_id`: AWS account id (e.g., '123456789012')
   *
   * - `account_info`: updated AWS account information
   *
   * @return Updated AWS account info
   */
  putOrganizationsCkOrgIdAccountsAccountId(params: AccountsService.PutOrganizationsCkOrgIdAccountsAccountIdParams): __Observable<{data?: Account}> {
    return this.putOrganizationsCkOrgIdAccountsAccountIdResponse(params).pipe(
      __map(_r => _r.body as {data?: Account})
    );
  }
}

module AccountsService {

  /**
   * Parameters for postOrganizationsCkOrgIdAccounts
   */
  export interface PostOrganizationsCkOrgIdAccountsParams {

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * new AWS account information
     */
    accountInfo?: AccountPost;
  }

  /**
   * Parameters for deleteOrganizationsCkOrgIdAccountsAccountId
   */
  export interface DeleteOrganizationsCkOrgIdAccountsAccountIdParams {

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;
  }

  /**
   * Parameters for getOrganizationsCkOrgIdAccountsAccountId
   */
  export interface GetOrganizationsCkOrgIdAccountsAccountIdParams {

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;
  }

  /**
   * Parameters for putOrganizationsCkOrgIdAccountsAccountId
   */
  export interface PutOrganizationsCkOrgIdAccountsAccountIdParams {

    /**
     * CloudKeep organization id
     */
    ckOrgId: string;

    /**
     * AWS account id (e.g., '123456789012')
     */
    accountId: string;

    /**
     * updated AWS account information
     */
    accountInfo?: AccountPut;
  }
}

export { AccountsService }
