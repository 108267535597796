import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-org-layout',
  templateUrl: './org-layout.component.html',
  styleUrls: ['./org-layout.component.scss']
})
export class OrgLayoutComponent {

  constructor(
  ) {}

}
