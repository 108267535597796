import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestOptionsArgs, HttpService } from './http.service';
import { errorMockup, sleep } from '../utils';

const SLEEP_INTERVAL_MS = 250;

@Injectable()
export class HttpMockupService extends HttpService {

    constructor(
        _http: HttpClient,
    ) {
        super(_http);
     }

    async get<T = any>(url: string, options?: RequestOptionsArgs, mockup?: T) {
        try {
            return await this._http.get<T>(this._getUrl(url), options).toPromise();
        } catch (err) {
            await sleep(SLEEP_INTERVAL_MS);
            errorMockup(err, mockup);
            return mockup;
        }
    }

    async patch<T = any>(url: string, body, options?: RequestOptionsArgs, mockup?: T) {
        try {
            return await this._http.patch<T>(this._getUrl(url), body, options).toPromise();
        } catch (err) {
            await sleep(SLEEP_INTERVAL_MS);
            errorMockup(err, mockup);
            return mockup;
        }
    }

    async put<T = any>(url: string, body, options?: RequestOptionsArgs, mockup?: T) {
        try {
            return await super.put<T>(this._getUrl(url), body, options);
        } catch (err) {
            await sleep(SLEEP_INTERVAL_MS);
            errorMockup(err, mockup);
            return mockup;
        }
    }

    async post<T = any>(url: string, body, options?: RequestOptionsArgs, mockup?: T) {
        try {
            return await this._http.post<T>(this._getUrl(url), body, options).toPromise();
        } catch (err) {
            await sleep(SLEEP_INTERVAL_MS);
            errorMockup(err, mockup);
            return mockup;
        }
    }

    async delete(url, options?: RequestOptionsArgs, mockup?) {
        try {
            return await this._http.delete(this._getUrl(url), options).toPromise();
        } catch (err) {
            await sleep(SLEEP_INTERVAL_MS);
            errorMockup(err, mockup);
            return mockup;
        }
    }

}
