/* tslint:disable */
import { Injectable, ComponentFactoryResolver, Inject, ReflectiveInjector } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class ModalService  {
  
  private rootViewContainer;

  constructor(
    private factoryResolver: ComponentFactoryResolver
  ) {
    
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  addDynamicComponent(modalSub,data) {
    const factory = this.factoryResolver.resolveComponentFactory(modalSub)
    const component = factory.create(this.rootViewContainer.parentInjector)
    this.rootViewContainer.insert(component.hostView)
    component.instance["options"] = data;
  }
}
