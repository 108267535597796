import * as moment from 'moment';

export interface EbsSnapInfoMock {
  account_alias: string;
  account_id: string;
  availability_zone_id: string;
  instance_id: string;
  region_id: string;
  volume_id: string;
  last_snapshot_time: string;
  last_snapshot_time_group?: string;
  last_snapshot_time_group_pos?: string
};

function getSnapshotMock(i: number) {
  return {
    "volume_id" : `vol-059aa9af645cbcee${999-i}`, 
    "last_snapshot_time" : i === 999 ? null : `${moment().add(-i, 'day').toDate().toISOString()}`,
    "instance_id" : `i-02c7fb824dc23d72${i}`,
    "availability_zone_id" : `us-east-1${999-i}`,
    "region_id" : `us-east-1`,
    "account_alias" : `Sandbox01`,
    "account_id" : `7576-6552-718${999-i}`,
  };
}

export const SNAPSHOTS_MOCK = () => ({ 
  data: [
    getSnapshotMock(0),
    getSnapshotMock(1),
    getSnapshotMock(2),
    getSnapshotMock(3),
    getSnapshotMock(4),
    getSnapshotMock(5),
    getSnapshotMock(6),
    getSnapshotMock(7),
    getSnapshotMock(8),
    getSnapshotMock(9),
    getSnapshotMock(98),
    getSnapshotMock(99),
    getSnapshotMock(100),
    getSnapshotMock(999),
    getSnapshotMock(999),
    getSnapshotMock(999),
  ] as EbsSnapInfoMock[]
});

// Attachment
export interface EbsDetachedInfoMock {
  account_alias: string;
  account_id: string;
  availability_zone_id: string;
  instance_id: string;
  last_attached_instance_id: string;
  region_id: string;
  volume_id: string;
  last_attachment_time: string;
  last_attachment_time_group?: string;
  last_attachment_time_group_pos?: string
};

function getAttachmentMock(i: number) {
  return {
    "volume_id" : `vol-059aa9af645cbcee${999-i}`, 
    "last_attachment_time" : i === 999 ? null : `${moment().add(-i, 'day').toDate().toISOString()}`,
    "instance_id" : `i-02c7fb824dc23d72${i}`,
    "last_attached_instance_id" : `i-02c7fb824dc23d72${i}`,
    "availability_zone_id" : `us-east-1${999-i}`,
    "region_id" : `us-east-1`,
    "account_alias" : `Sandbox01`,
    "account_id" : `7576-6552-718${999-i}`,
  };
}

export const ATTACHMENT_MOCK = () => ({
  data: [
    getAttachmentMock(0),
    getAttachmentMock(1),
    getAttachmentMock(2),
    getAttachmentMock(3),
    getAttachmentMock(4),
    getAttachmentMock(5),
    getAttachmentMock(6),
    getAttachmentMock(7),
    getAttachmentMock(8),
    getAttachmentMock(9),
    getAttachmentMock(98),
    getAttachmentMock(99),
    getAttachmentMock(100),
    getAttachmentMock(999),
    getAttachmentMock(999),
    getAttachmentMock(999),
  ] as EbsDetachedInfoMock[]
});

// Public Snapshot
export interface EbsPublicSnapInfoMock {
  account_alias: string;
  account_id: string;
  availability_zone_id: string;
  instance_id: string;
  region_id: string;
  volume_id: string;
  public_time: string;
  public_time_group?: string;
  public_time_group_pos?: string
};

function getPublicSnapMock(i: number) {
  return {
    "volume_id" : `vol-059aa9af645cbcee${999-i}`, 
    "public_time" : i === 999 ? null : `${moment().add(-i, 'day').toDate().toISOString()}`,
    "instance_id" : `i-02c7fb824dc23d72${i}`,
    "last_attached_instance_id" : `i-02c7fb824dc23d72${i}`,
    "availability_zone_id" : `us-east-1${999-i}`,
    "region_id" : `us-east-1`,
    "account_alias" : `Sandbox01`,
    "account_id" : `7576-6552-718${999-i}`,
  };
}

export const PUBLIC_SNAPSHOT_MOCK = () => ({
  data: [
    getPublicSnapMock(0),
    getPublicSnapMock(1),
    getPublicSnapMock(2),
    getPublicSnapMock(3),
    getPublicSnapMock(4),
    getPublicSnapMock(5),
    getPublicSnapMock(6),
    getPublicSnapMock(7),
    getPublicSnapMock(8),
    getPublicSnapMock(9),
    getPublicSnapMock(98),
    getPublicSnapMock(99),
    getPublicSnapMock(100),
    getPublicSnapMock(999),
    getPublicSnapMock(999),
    getPublicSnapMock(999),
  ] as EbsPublicSnapInfoMock[]
});