import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { ChartDonutSmallComponent } from './chart-donut-small/chart-donut-small.component';
import { ChartDonutComponent } from './chart-donut/chart-donut.component';

const COMPONENTS = [
    ChartDonutSmallComponent,
    ChartDonutComponent,
];

@NgModule({
    imports: [
        CommonModule,
        ChartsModule,
    ],
    declarations: COMPONENTS,
    exports: [
        ...COMPONENTS,
    ]
})
export class ComponentsModule {}
