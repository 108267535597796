import {Component, OnDestroy, OnInit} from '@angular/core';
import {Organization} from '../ck-user-api/models/organization';
import {Subscription} from 'rxjs';
import {OrgWrapperService} from '../shared/org.wrapper.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '', title: 'Dashboard',  icon: 'dashboard', class: '' },
    // { path: 'aws', title: 'AWS Accounts',  icon:'cloud_queue', class: '' },
    // { path: 'ebs', title: 'EBS Volumes',  icon:'data_usage', class: '' },
    // { path: 'settings', title: 'Settings',  icon:'settings', class: '' }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar-component.html',
    styleUrls: ['./sidebar-component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy {

  public selectOrg: Organization;
  public orgList: Organization[];
  private orgSelectSubscription: Subscription;
  private orgListSubscription: Subscription;


  menuItems: any[];


  constructor(
    private orgWrapperService: OrgWrapperService,
  ) {
  }

  ngOnInit(): void {
    this.selectOrg = this.orgWrapperService.selectedOrg;
    this.orgList = this.orgWrapperService.userOrgs;
    this.orgSelectSubscription = this.orgWrapperService.selectedOrgSubject.subscribe(
      (selectedOrg: Organization) => {
        this.selectOrg = selectedOrg;
      }
    );

    this.orgListSubscription = this.orgWrapperService.userOrgsSubject.subscribe(
      (userOrgs: Organization[]) => {
        this.orgList = userOrgs;
      }
    );

    this.menuItems = ROUTES.filter(menuItem => menuItem);

  }

  ngOnDestroy(): void {
    this.orgSelectSubscription.unsubscribe();
    this.orgListSubscription.unsubscribe();
  }

}
