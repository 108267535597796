import {Component, OnDestroy, OnInit,ComponentRef,ViewChild,ViewContainerRef,ElementRef} from '@angular/core';
import {IModalDialog,IModalDialogButton,IModalDialogOptions} from 'ngx-modal-dialog';
import {ModalService} from './base-modal.service';

@Component({
  selector: 'app-base-modal',
  template: '<ng-template #dynamic ></ng-template>',
  outputs :['closeFunction']
})
export class BaseModalComponent implements IModalDialog {

  private initFunction;
  actionButtons: IModalDialogButton[];
  private options;

  constructor(
    private modalService : ModalService,
    private elementRef: ElementRef
    ) {
    this.actionButtons = [
    ];
  }

  @ViewChild('dynamic', { 
    read: ViewContainerRef 
  }) viewContainerRef: ViewContainerRef

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    // no processing needed
    //this.initFunction(options); 
    options.data.closeDialogSubject = options.closeDialogSubject;
    this.options = options;
    this.modalService.setRootViewContainerRef(this.viewContainerRef)
    this.modalService.addDynamicComponent(options.data.insideComponent,options.data);
  }

}

